// Dependency
import React, { useRef, useState } from "react"
import { Link } from "gatsby";
import Img from "gatsby-image";

// Components
import { useCart } from "../../contexts/cart-context";

// Services
import UtilsService from "../../services/utils.service";
import GA from "../../services/ga.service";

// Internationalization
import { useTranslation } from 'react-i18next';

// Hooks
import useIntersection from '../../templates/product/hooks/useIntersection';

// Styles
import * as ProductCardStyles from './product-card.module.scss'

// Assets
import shoppingBag from "../../images/shopping-bag.png";
import atc from "../../images/medical_services.svg"
import Video from "../video";
import * as productStyles from "../../templates/product/product.module.scss";

// Components
import MiniLoader from "../loader/mini-loader";

// Templates
const ProductCard = ({ centraProd, data, cname, dataTheme, placement, position, color, labelBackground}) => {
  const list = placement ? placement : 'Product List';
  const pos = position ? position : 1;

  const [viewedProducts, setViewedProducts] = useState([]);

  // console.log('data: ', data);
  const { addToCart } = useCart();
  // pull data for this poduct from Centra
  const centraProductData = centraProd;//useProgramData(`${process.env.CENTRA_URL_PRODUCTS}/${data.centraId}`);
  // Translation Function
  const { t } = useTranslation();

  const ref = useRef();

  const inViewport = useIntersection(ref.current, '-250px');

  const [isLoading, setIsLoading] = useState(false)

  let buyButton = t('productCard.addToCart');

  // if ( data.slug && data.slug === 'flowfeet'  ) {
  //   buyButton = t('productInfo.preOrder')
  // }

  if (inViewport) {
    let unique = `${centraProd.product}${list}`;
    if (!viewedProducts.includes(unique)) {
      GA.onProductView(centraProd, list, pos);
      setViewedProducts([...viewedProducts, unique])
    }
  }

  const handleAddToCart = async () => {
    const res = await addToCart(centraProductData && centraProductData.items[0].item, list, centraProductData);
    if (res) setIsLoading(false)
  }

  const roundedDiscount = Math.round(centraProductData.discountPercent)

  return (
    <article ref={ref} className={` ${ProductCardStyles.cdProductCard} ${cname ? cname : ""} cd-product-card`}>
      <div className={`${ProductCardStyles.cdProductCardImg} cd-background-img`}>
        {
          data.cardVideo && data.cardVideo.url ? <div><Video autoplay={'autoplay'} thumb={`${data.cardVideoThumbnail ? data.cardVideoThumbnail.fluid : ''}`} videoSrcURL={data.cardVideo.url} /></div>
            : data.blackWeekImage ? <Img alt="" fluid={data.blackWeekImage.fluid} />
              : <Img alt="" fluid={data.featuredImage?.fluid} />
        }
        {/*{ data.featuredImage && <Img alt="" fluid={data.featuredImage.fluid} /> }*/}
          {roundedDiscount > 0 ?
            <span style={{color: color, backgroundColor: labelBackground}} className={ProductCardStyles.cdProductSale}>Christmas Sale</span>
            : ''
          }
        {centraProductData && centraProductData.items && centraProductData.items[0] && centraProductData.items[0].stock === "no" ?
            null
            :
            <a href={'#0'} className={`${ProductCardStyles.cdProductCardQuickAtc} cd-product-card-quick-atc`}
              onClick={(event) => {
                setIsLoading(true);
                event.preventDefault();
                handleAddToCart()
              }}>
                <img src={atc} alt="Shopping bag" />
             
              <MiniLoader theme={"dark"} active={isLoading} />
            </a>
          }
      </div>
      {/* <div className={`${ProductCardStyles.cdProductCardBanner}`}>
        <p>Black Friday</p>
      </div> */}
      <div className={`${ProductCardStyles.cdProductCardContent} cd-product-card-content cd-dark-mode-dark`} >
        <div className={ProductCardStyles.cdProductCardContentLeft}>
          <div className={ProductCardStyles.cdProductCardTitle}>
            {data && data.title && <h2 className="">{data.parentProductName ? data.parentProductName : data.title}</h2>}
          </div>
          {/* {
            data && data.trustpilotId &&
            <>
             <TestFreak
                  props={{
                    reviews: false,
                    type: "items",
                    productId: data.trustpilotId,
                    country: UtilsService.getLocaleFromUrl().country
                  }}
                />
            </>
          }  */}

        </div>
        <div className={ProductCardStyles.cdProductCardContentRight}>
          <div className={`${ProductCardStyles.cdProductPrice} cd-product-price`}>
            <span className={`${centraProductData && centraProductData.priceBeforeDiscountAsNumber !== centraProductData.priceAsNumber || data && data.newPrice ? 'cd-price-red' : ''} cd-product-price-value`}>{centraProductData && centraProductData.price}</span>
          </div>
          {centraProductData && centraProductData.priceBeforeDiscountAsNumber !== centraProductData.priceAsNumber ?
            <div className={`${ProductCardStyles.cdProductOriginalPrice} cd-product-price-original`}>
              <span className="cd-product-price-value">{centraProductData && centraProductData.priceBeforeDiscount}</span>
            </div> : ''}
          {
            data && data.newPrice ?
              <div className={productStyles.cdNewPrice}>
                <div className={`${ProductCardStyles.cdProductOriginalPrice}`}>
                  <span className="cd-product-price-value">{data.newPrice}</span>
                </div>
              </div>
              :
              ''
          }
          {roundedDiscount > 0 ?
            <span className={ProductCardStyles.cdProductPriceDiscount}> -{roundedDiscount}%</span>
            : ''
          }
        </div>
        <div className={`${ProductCardStyles.cdSaleBadges}`}>
          {
            data.campaignSiteBadge ? <div className={`${ProductCardStyles.cdSaleBadge} cd-sale-badge`}>
              <p>{data.campaignSiteBadge}</p>
            </div>
              :
              data.saleBadge ? <div className={`${ProductCardStyles.cdSaleBadge} `}>
                <p>{data.saleBadge}</p>
              </div>
                :
                ""
          }
          {data.bestseller ? <div className={`${ProductCardStyles.cdSaleBadge} `}>
            <p>{t('productCard.bestseller')}</p>
          </div> : ""}
        </div>
      </div>
      <Link onClick={() => GA.onProductClick(centraProductData, list, pos)} to={`/${UtilsService.getLocaleFromUrl().country}/${t('slug.product')}/${data.slug}/`} className="cd-absolute-link"></Link>
    </article>
  )
}

export default ProductCard;